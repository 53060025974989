
import Vue from 'vue';
import Component from 'vue-class-component';
import MainCard from '@/components/MainCard.vue';

@Component({
  components: {
    MainCard: MainCard
  }
})
export default class ModuleSettingsView extends Vue {
  orderingRules(): void {
    this.$router.push({
      name: 'OrderingRules'
    });
  }
}
